import React, { useContext, useEffect, useState, useCallback, useMemo } from 'react';
import ccpa from 'crypto-pro-cadesplugin';

import usb from '../../../icons/usb.svg';
import check from '../../../images/Check.svg';
import checkEmpty from '../../../images/Check_Empty.svg';
import pdf from '../../../icons/PdfIcon.svg';
import avatar from '../../../images/Avatar_2.svg';
import styles from '../../../Pages/Building/Homes/Modal/homes.modal.module.css';
import useFileService from '../../../Services/FileService';
import { HttpContext } from '../../../HttpContext';
import { useSignmeService } from '../../../Services/SignMe/SignmeService';
import getCertsList from './getCertsList';
import SmallCard from '../../../Components/SmallCard/SmallCard';
import ModalCenter from '../../../Components/ModalCenter/ModalCenter';
import Check from '../../../Components/Check/Check';
import Button from '../../../Components/Button/Button';
import signBadge from './signBadge';
import Badge from '../../../Components/Badge/Badge';
import { useUlService } from '../../../Services/UlSubjectService';
import useRequestContext from '../useRequestContext';
import statuses from '../../../Services/SignMe/signMeStatuses.json';

const SignatureModalCenter = ({ isOpen, onClose, documents, participants, setParticipants, onChange, addSignature, signatureType, setSignatureType }) => {
  const { downloadFile } = useFileService()
  const { getUlSubjects, getUlAgent } = useUlService();
  const { request, getRequest } = useRequestContext()
  const { state } = useContext(HttpContext);
  const { participant } = state;

  const { getCertificateInfo, signDocument } = useSignmeService();

  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const [certs, setCerts] = useState([]);
  const [saveCertificate, setSaveCertificate] = useState(false);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [ulSubjects, setUlSubjects] = useState([]);
  const [participantStatuses, setParticipantStatuses] = useState({});

  const fetchCerts = useCallback(async () => {
    const certsList = await getCertsList();
    const formattedCerts = certsList.map((cert) => {
      const subjectInfo = cert.subjectInfo;
      let info = cert.friendlyIssuerInfo()[0].text;
      const valid = cert.friendlyValidPeriod().to.ddmmyy;

      if (info.startsWith('"') && info.endsWith('"')) {
        info = info.slice(1, -1);
      }

      let subject = '';
      const organizationMatch = subjectInfo.match(/O=([^,]+)/);
      if (organizationMatch) {
        subject = organizationMatch[1];
      } else {
        const cnMatch = subjectInfo.match(/CN=([^,]+)/);
        subject = cnMatch ? cnMatch[1] : subjectInfo;
      }

      return { ...cert, subject, valid, info };
    });
    setCerts(formattedCerts);
  }, []);

  const getUlAgentName = (ulId, agentId) => {
    return (ulSubjects?.find(subject => subject.Id === ulId)?.Agents?.find((agent) => agent?.Id === agentId))?.FlSubjectFIO
  }

  const toggleDocumentSelection = (participantId, documentId) => {
    setSelectedParticipants((prev) => {
      const participantExists = prev.some((participant) => participant.Id === participantId);

      if (!participantExists) {
        const participantToAdd = participants.find((p) => p.Id === participantId);
        if (participantToAdd) {
          const updatedDocuments = participantToAdd.Documents.map((doc) =>
            doc.Id === documentId ? { ...doc, isActive: true } : doc
          );
          return [...prev, { ...participantToAdd, Documents: updatedDocuments }];
        }
        return prev;
      }

      return prev.map((participant) => {
        if (participant.Id === participantId) {
          const updatedDocuments = participant.Documents.map((doc) =>
            doc.Id === documentId ? { ...doc, isActive: !doc.isActive } : doc
          );
          return { ...participant, Documents: updatedDocuments };
        }
        return participant;
      });
    });
  };

  const handleSign = () => {
    if (signatureType === 'token') {
      handleSignToken()
    } else if (signatureType === 'goskey') {
      // handleSignGoskey()
    } else if (signatureType === 'signme') {
      handleSignDocuments()
    }
  }

  const handleSignToken = async () => {
    const signPromises = selectedParticipants.flatMap((participant) =>
      participant.Documents.filter((document) => document.isActive).map(async (document) => {
        try {
          const base64 = await downloadFile(document?.FileUuid);
          const signed = await ccpa().then((x) => x.signBase64(selectedCertificate.thumbprint, base64));
          await addSignature(document.Id, participant.Id, null, signed, 2);

          await getRequest(request.Id);
        } catch (error) {
          console.log(error);
        }
      })
    );

    await Promise.all(signPromises);

    if (saveCertificate) {
      saveToLocalStorage('signature', selectedCertificate?.thumbprint);
    } else {
      removeFromLocalStorage('signature');
    }

    onClose();
  };

  const handleSignDocuments = async () => {
    const participantDocuments = selectedParticipants.map((participant) => ({
      participantId: participant.Id,
      documentIds: participant.Documents.filter((doc) => doc.isActive).map((doc) => doc.Id),
    })).filter((entry) => entry.documentIds.length > 0);

    const signPromises = participantDocuments.map(({ participantId, documentIds }) =>
      signDocument(participantId, documentIds)
    );

    await Promise.all(signPromises);

    await getRequest(request.Id);
    onClose();
  };

  useEffect(() => {
    if (signatureType === 'signme') {
      const fetchStatuses = async () => {
        const statusesMap = {};

        const fetchPromises = participants.map(async (participant) => {
          const subject = participant?.FlSubject || participant?.UlAgent || participant?.UlAgentFlSubject;

          if (subject?.UlSubjectId) {
            const ulAgent = await getUlAgent(subject?.UlAgentId);
            if (ulAgent?.FlSubjectId) {
              const info = await getCertificateInfo(ulAgent?.FlSubjectId);
              const status = statuses.find((status) => status.code === info?.Status?.code);
              if (status) {
                statusesMap[subject.FlSubjectId] = status;
              }
            }
          } else if (subject?.FlSubjectId) {
            const info = await getCertificateInfo(subject.FlSubjectId);
            const status = statuses.find((status) => status.code === info?.Status?.code);
            if (status) {
              statusesMap[subject.FlSubjectId] = status;
            }
          }
        });

        await Promise.all(fetchPromises);

        setParticipantStatuses(statusesMap);
      };

      if (isOpen) {
        fetchStatuses();
      }
    }
  }, [isOpen]);


  // const handleSignGoskey = async () => {
  // };

  useEffect(() => {
    if (isOpen && signatureType === 'token') {
      fetchCerts();
    } else {
      setSignatureType(signatureType ? signatureType : null);
    }
  }, [isOpen, signatureType, fetchCerts]);

  useEffect(() => {
    getUlSubjects().then(result => setUlSubjects(result))
  }, [])

  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
  };

  const getFromLocalStorage = (key) => {
    return localStorage.getItem(key);
  };

  useEffect(() => {
    const storedValue = getFromLocalStorage('signature');
    if (storedValue) {
      setSelectedCertificate({
        thumbprint: storedValue
      });
      setSaveCertificate(true);
    } else {
      setSelectedCertificate({
        thumbprint: null
      });
      setSaveCertificate(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen && Array.isArray(participants)) {
      const updatedParticipants = participants.map((participant) => {
        const hasDocumentsReadyToSign = participant.Documents.some((document) =>
          [0, 1].includes(document.Signature?.Status)
        );

        const updatedDocuments = participant.Documents.map((document) => ({
          ...document,
          isActive: [0, 1].includes(document.Signature?.Status),
        }));

        return hasDocumentsReadyToSign ? { ...participant, Documents: updatedDocuments } : null;
      });

      setSelectedParticipants(updatedParticipants.filter(Boolean));
    }
  }, [isOpen, participants]);


  const renderCerts = useMemo(() => {
    return certs.map((x) => (
      <SmallCard
        key={x.thumbprint}
        avatar={selectedCertificate.thumbprint === x.thumbprint ? check : checkEmpty}
        type={selectedCertificate.thumbprint === x.thumbprint ? "primary" : "default"}
        onClick={() => setSelectedCertificate(x)}
        title={x.info}
      >
        <label className={styles.signature_text}>{x.subject}</label>
        <label className={styles.signature_text}>{`Действует до: ${x.valid}`}</label>
      </SmallCard>
    ));
  }, [certs, selectedCertificate]);

  return (
    <ModalCenter isOpen={isOpen}>
      <div className={styles.signature}>
        <div>
          <div className={styles.signature}>
            <h2>Подписание документов</h2>
            <label>Подписант</label>
            {participants?.map((x) => {
              const subject = x?.FlSubject || x?.UlAgent || x?.UlAgentFlSubject;

              const title = `${subject?.Lastname} ${subject?.Firstname} ${subject?.Middlename}`;
              const status = participantStatuses[subject?.FlSubjectId];
              const isSelected = selectedParticipants?.some((participant) => participant.Id === x.Id);
              const allDocumentsSigned = x?.Documents?.some((item) => [0, 1].includes(item?.Signature?.Status));

              return (
                <SmallCard
                  key={x.Id}
                  title={title}
                  onClick={() => {
                    if (allDocumentsSigned) {
                      setSelectedParticipants((prev) => {
                        const currentSelected = Array.isArray(prev) ? prev : [];
                        if (currentSelected.some((participant) => participant.Id === x.Id)) {
                          return currentSelected.filter((participant) => participant.Id !== x.Id);
                        } else {
                          return [...currentSelected, x];
                        }
                      });
                    }
                  }}
                  subtitle={x?.UlAgentFlSubject
                    ? `Представитель: ${ulSubjects.find((subject) => subject.Id === x?.UlAgentFlSubject?.UlSubjectId)?.Name}`
                    : `СНИЛС: ${subject?.Snils}`}
                  avatar={!allDocumentsSigned ? avatar : isSelected ? check : checkEmpty}
                  type={!allDocumentsSigned ? "default" : isSelected ? "primary" : "default"}
                  badge={signatureType === "signme" && <Badge type={status?.type} text={status?.name} />}
                >
                  {x?.UlAgentFlSubject && (
                    <label className={styles.subtitle}>
                      <span>Подписант: </span>
                      {getUlAgentName(x?.UlAgentFlSubject?.UlSubjectId, x?.UlAgentFlSubject?.UlAgentId)}
                    </label>
                  )}
                </SmallCard>
              );
            })}

            <label>Документы на подпись</label>
            {participants?.map((participant) => {
              const subject = participant?.FlSubject || participant?.UlAgent || participant?.UlAgentFlSubject;
              const fullName = `${subject?.Lastname || ""} ${subject?.Firstname || ""} ${subject?.Middlename || ""}`;

              return (
                <div className={styles.signature} key={participant?.Id}>
                  <div className={styles.status}>
                    <h4>{fullName}</h4>
                    {signatureType === "signme" &&
                      <Button
                        type='info'
                        onClick={() => {
                          window.open(`/customer/edit/${subject?.FlSubjectId}/fl`, '_blank');
                        }}
                        text='Перейти в Клиенты'
                      />
                    }
                  </div>
                  {participant?.Documents?.map((document) => {
                    if (document?.Category?.code === '3') {
                      return null;
                    }

                    const participantId = participant?.Id;
                    const isSelected = selectedParticipants.some(
                      (selectedParticipant) =>
                        selectedParticipant.Id === participant.Id &&
                        selectedParticipant.Documents.some((doc) => doc.Id === document.Id && doc.isActive)
                    );

                    const isSignable = document?.Signatures?.length === 0 || document?.Signature?.Status === 1;

                    return isSignable ? (
                      <SmallCard
                        title={document?.FileName}
                        key={document.FileName}
                        onClick={() => toggleDocumentSelection(participantId, document?.Id)}
                        avatar={isSelected ? check : checkEmpty}
                        type={isSelected ? "primary" : "default"}
                        subtitle={document?.Type?.name}
                        badge={signBadge(document?.Signature)}
                      />
                    ) : (
                      <SmallCard
                        title={document?.FileName}
                        key={document.FileName}
                        badge={signBadge(document?.Signature)}
                        avatar={pdf}
                        subtitle={document?.Type?.name}
                      />
                    );
                  })}

                </div>
              );
            })}
            {signatureType === 'token' ? (
              <div className={styles.signature}>
                <label>Выберите сертификат</label>
                {renderCerts}
                {certs.length ? (
                  <Check
                    onClick={() => setSaveCertificate(!saveCertificate)}
                    value={saveCertificate}
                    selected={saveCertificate}
                    label='Запомнить выбранный сертификат'
                  />
                ) : null}
              </div>
            ) : null}
            <div className={styles.sigButtons}>
              <Button
                isDisabled={
                  selectedParticipants?.length === 0 ||
                  !selectedParticipants?.some((participant) => {
                    return participant?.Documents?.some((document) => {
                      const subject =
                        participant?.FlSubject ||
                        participant?.UlAgent ||
                        participant?.UlAgentFlSubject;

                      return (
                        document?.isActive &&
                        (document?.Signature?.Status === 0 || document?.Signature?.Status === 1) &&
                        (signatureType !== "signme" || participantStatuses[subject?.FlSubjectId]?.code === 2)
                      );
                    });
                  })
                }
                onClick={handleSign}
                text="Подписать"
              />

              <Button
                type="gray"
                onClick={() => {
                  setSignatureType(null);
                  onClose(false);
                }}
                text="Отмена"
              />
            </div>
          </div>
        </div>
      </div>
    </ModalCenter>
  );
};

export default React.memo(SignatureModalCenter);
