import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FormControlLabel, FormGroup, Switch } from '@mui/material';

import Button from '../../Components/Button/Button';

import styles from './offer.module.css';
import { useGrazhdotekaService } from '../../Services/Grazhdoteka/Grazhdoteka';

const Offer = () => {
  const [isDownload, setIsDownload] = useState(true);
  const [isConfirm, setIsConfirm] = useState(true);
  const location = useLocation();
  const {getAuthLink} = useGrazhdotekaService();

  const queryParams = new URLSearchParams(location.search);
  const customerId = queryParams.get('customerId');

  const isMobileDevice = () => /Mobi|Android/i.test(navigator.userAgent);

  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <h3>Для перехода на страницу авторизации в Госуслугах, ознакомьтесь с офертой:</h3>
        <Button
          onClick={() => {
            setTimeout(() => {
              setIsDownload(false);
            }, 1000);
          }}

          large
          type='info'
          href='https://spd-3.ru/files/oferta.pdf'
          text='Скачать файл оферты'
          blank
        />
        <FormGroup>
          <FormControlLabel control={<Switch onChange={() => setIsConfirm(!isConfirm)} disabled={isDownload} />} label='Ознакомлен' />
        </FormGroup>
        <div className={styles.button}>
          <Button
            isDisabled={isConfirm}
            large
            text='Продолжить'
            onClick={() => {
              getAuthLink(customerId).then((link) => {
                if (link?.AuthUrl) {
                  if (isMobileDevice()) {
                    window.location.href = link.AuthUrl;
                  } else {
                    window.open(link.AuthUrl, "_blank");
                  }
                }
              })
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Offer;
