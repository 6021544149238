import { useContext } from 'react';
import { HttpContext } from '../../HttpContext';

export function useCustomerService() {
  const { httpClient } = useContext(HttpContext);

  const getCustomerList = async () => {
    return httpClient.get("/customer/customer-list").then(promise => promise.data)
  }

  const addPayBalance = async (customerId, value) => {
    return httpClient.post(`/customer/add-pay-balance?customerId=${customerId}&value=${value}`).then(promise => promise.data)
  }

  const getPayBalance = async () => {
    return httpClient.get("/customer/pay-balance").then(promise => promise.data)
  }

  const getPayBalanceList = async () => {
    return httpClient.get("/customer/pay-balance-list").then(promise => promise.data)
  }

  const getSharedPayBalance = async () => {
    return httpClient.get("/customer/shared-pay-balance").then(promise => promise.data)
  }

  const getPayOperations = async () => {
    return httpClient.get("/customer/pay-operations").then(promise => promise.data)
  }

  const getBalanceHistory = async (id) => {
    return httpClient.get(`/customer/pay-balance-history?customerId=${id}`).then(promise => promise.data)
  }

  const getBalanceStatistic = async (statPeriod) => {
    return httpClient.get(`/customer/pay-balance-statistic?statPeriod=${statPeriod}`, {
      responseType: 'arraybuffer',
    }).then(promise => promise.data)
  }

  return {getCustomerList, addPayBalance, getPayBalance, getPayBalanceList, getSharedPayBalance, getPayOperations, getBalanceHistory, getBalanceStatistic}
}
