import React, { useState, useRef, useEffect } from 'react';

import styles from "./input.module.css";
import { useEstateObjectService } from '../../Services/EstateObject/EstateObject';

export default function ObjectInput({ value, setValue }) {
  const [isOptions, setIsOptions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const inputRef = useRef(null);

  const {getEstateObjects} = useEstateObjectService();
  const [options, setOptions] = useState([])

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      setIsOptions(false);
      setSearchValue('');
      setIsSearch(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.container} style={{ display: "flex" }} ref={inputRef}>
      <label>Поиск по объектам</label>
      <input
        className={styles.input}
        value={isSearch ? searchValue : value?.CadNumber}
        type='text'
        onChange={(e) => {
          setSearchValue(e.target.value);
          getEstateObjects(1, e.target.value).then((object) => setOptions(object?.Items))
        }}
        onClick={() => {
          setIsOptions(true);
          setIsSearch(true);
          setSearchValue('')
        }}
        placeholder='Введите кадастровый номер или адрес'
      />
      {(isOptions &&
        <div className={styles.options} style={{ flexDirection: 'column' }}>
          {options.map((x) =>
            <span
              key={x.Id}
              onClick={(e) => {
                setValue(x);
                setIsOptions(false);
                e.preventDefault();
                setSearchValue('');
                setIsSearch(false);
              }}
            >
              {`${ x?.CadNumber }, ${ x?.Address }`}
            </span>
          )}
          {options.length === 0 &&
            <span>Не найдено</span>
          }
        </div>
      )}
    </div>
  );
}
