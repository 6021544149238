import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import Button from "../../../Components/Button/Button";
import CardHeader from "../../../Components/Card/CardHeader";
import Input from "../../../Components/Input/Input";
import RequestFooter from "../../../Components/RequestFooter/RequestFooter";
import Select from "../../../Components/Select/Select";
import TextArea from "../../../Components/TextArea/TextArea";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import Check from "../../../Components/Check/Check";
import EmptyBlock from "../../../Components/EmptyBlock/EmptyBlock";
import FileInput from "../../../Components/FileInput/FileInput";
import CardContainer from "../../../Components/Card/CardContainer";
import CardBody from "../../../Components/Card/CardBody";
import ObjectModal from "../../Object/ObjectModal/ObjectModal";
import DocumentModal from "../../Document/DocumentModal";
import useRequestContext from "../useRequestContext";
import { mimeTypes } from "../../../constants/constants";
import DocumentCard from "../../../Components/DocumentCard/DocumentCard";
import { useCustomerService } from '../../../Services/Cusomer/Customer';

import regTypesData from "./regTypesData.json";
import styles from "./request.create.module.css"
import plus from "../../../icons/plus-violet.svg"
import Modal from "../../../Components/Modal/Modal";
import avatar from "../../../images/Avatar_2.svg"
import Dropdown from "../../../Components/Dropdown/Dropdown";
import ModalCenter from "../../../Components/ModalCenter/ModalCenter";
import pdfIcon from "../../../icons/PdfIcon.svg"
import uploadIcon from "../../../icons/upload-icon.svg"
import checkEmpty from "../../../images/Check_Empty.svg"
import checkFilled from "../../../images/Check.svg"
import avatarBuilding from "../../../images/Avatar_building.svg"

export default function CreateRequest() {
  const [isBuildingsOpen, setBuildingsOpen] = useState(false)
  const navigate = useNavigate();
  const { request, regTypes, rightTypes, setRequest, getRequests, saveDocument, documentTypes, requestStatuses, deleteDocument, status, deleteParticipant, objectTypes, addFlSubject, addFlSubjectWithAgent, generateStatement, createRequest, addUlAgent, id, isDisabled, addSignature, addUlRepresentingFl, sendStatement, addDocumentParticipant, deleteDocumentParticipant, getRequest, updateRequest } = useRequestContext();
  const [isDocumentModal, setDocumentModal] = useState(false)
  const [uploadedDocument, setUploadedDocument] = useState(null)

  const removeDocument = (id) => {
    deleteDocument(id).then(x => {
      const newState = request.Documents.filter(t => t.Id != id);
      setRequest({ ...request, Documents: newState })
    })
  }

  useEffect(() => {
    const type = regTypesData.find(x => x?.code === request?.RegAction?.code)
    if (!isNaN(id)) {
      navigate(type?.path.replace(":id", id))
    }
  }, [request?.RegAction?.code, id])

  return (<div className={styles.container}>
    <CardContainer>
      <CardHeader title="Регистрационное действие"/>
      <CardBody>
        <Select
          disabled={isDisabled}
          label="Тип регистрационного действия"
          value={request?.RegAction?.code}
          onChange={(code) => setRequest({ ...request, RegAction: { code: code } })}
        >
          {regTypes.map((x) =>
            <option value={x?.code}>{x?.name}</option>
          )}
        </Select>
        {/* {request?.RegAction?.code === 5 && */}
        <Select
          disabled={isDisabled}
          label="Тип права"
          value={request?.RightType?.code}
          onChange={(value) => setRequest({ ...request, RightType: { ...request?.RightType, code: value } })}
          placeholder="Выберите тип права"
        >
          {rightTypes.map((x) =>
            <option value={x?.code}>{x?.name}</option>
          )}
        </Select>
        {/* } */}
        {!isNaN(id) &&
          <React.Fragment>
            {request?.RegAction?.code != 5 &&
              <Select
                label="В кредит"
                disabled={isDisabled}
                value={request?.IsMortgage}
                onChange={(value) => setRequest({ ...request, IsMortgage: value })}
              >
                <option value={true}>Да</option>
                <option value={false}>Нет</option>
              </Select>
            }
            {request?.IsMortgage &&
              <Input
                label="Сумма кредита"
                value={request.SummCredit}
                onChange={(value) => setRequest({ ...request, SummCredit: value })}
                mask={Number}
              />
            }
          </React.Fragment>
        }
      </CardBody>
    </CardContainer>
    <CardContainer >
      <CardHeader title="Объект недвижимости"/>
      <CardBody>
        {((request?.Object?.Type != null && request?.Object?.Address?.Note != null && request?.Object?.CadNum != null) && request?.RegAction?.code != 1) &&
          <SmallCard
            onClick={isDisabled ? null : () => setBuildingsOpen(true)}
            type="default"
            title={request?.Object?.Address?.Note}
            subtitle={`${request?.Object?.Type?.name}, ${request?.Object?.CadNum}`}
            avatar={avatarBuilding}
          />
        }
        {(request?.Object != null && request?.RegAction?.code === 1) &&
          <SmallCard
            onClick={() => setBuildingsOpen(true)}
            type="default"
            title={`ЖК ${request?.Object?.Complex?.name}, ${request?.Object?.House?.name}`}
            subtitle={`${request?.Object?.Type?.name}, ${request?.Object?.CondCadNum}`}
            avatar={avatarBuilding}
          />
        }
        {(request?.Object?.Type == null || request?.Object?.Address?.Note == null || request?.Object?.CadNum == null) &&
          <React.Fragment>
            <EmptyBlock title="Объекты недвижимости" />
            <Button
              type="primary"
              text="Добавить объект недвижимости"
              large
              onClick={() => setBuildingsOpen(true)}
              icon={plus}
            />
          </React.Fragment>
        }
        {(isNaN(id) && request?.Object != null) &&
          <Button
            large
            type="primary"
            text="Создать заявку"
            onClick={() => createRequest(request?.RegAction?.code, request?.RightType?.code, request?.Object).then(x => navigate(`/request/edit/${x.data}`))}
          />
        }
      </CardBody>
    </CardContainer>
    <Outlet context={[request, regTypes, rightTypes, setRequest, getRequests, saveDocument, documentTypes, requestStatuses, deleteDocument, status, deleteParticipant, objectTypes, addFlSubject, addFlSubjectWithAgent, generateStatement, createRequest, addUlAgent, id, isDisabled, addSignature, addUlRepresentingFl, sendStatement, addDocumentParticipant, deleteDocumentParticipant, getRequest]} />
    {!isNaN(id) &&
      <CardContainer >
        <CardHeader title="Документы"/>
        <CardBody>
          {request?.Documents?.filter(x => !x?.FileName?.includes(".xml")).map((x) =>
              <DocumentCard
                onClick={() => {
                  setDocumentModal(true);
                  setUploadedDocument(x)
                }}
                document={x}
                isDisabled={isDisabled}
                removeDocument={removeDocument}
              />
          )}
          {request?.Documents?.filter(x => !x?.FileName?.includes(".xml")).length === 0 &&
            <EmptyBlock title="Текущие документы"/>
          }
          {!isDisabled &&
            <FileInput onClick={() => {
              setDocumentModal(true);
              setUploadedDocument(null)
            }} isDisabled={true} accept={[mimeTypes.Pdf, mimeTypes.Docx]} />
          }
        </CardBody>
      </CardContainer>
    }
    {!isNaN(id) &&
      <div className={styles.autopay}>
        <Check boldTitle selected={request?.IsAutoPay} onClick={() => {
          if (request?.IsAutoPay) {
            setRequest({ ...request, IsAutoPay: !request?.IsAutoPay, CustomerPayId: null })
          } else {
            setRequest({ ...request, IsAutoPay: !request?.IsAutoPay })
          }
        }} label='Автооплата'/>
        <div className={styles.agent}>
          <label>Плательщик:</label>
          <div className={styles.agentInput}>
            <Input disabled value={request?.Customer?.name}/>
          </div>
        </div>
      </div>
    }
    {(!isNaN(id) && !isDisabled) &&
      <CardContainer>
        <CardHeader title="Дополнительная информация"/>
        <CardBody>
          <TextArea
            value={request?.Description}
            onChange={(value) => setRequest({
              ...request,
              Description: value
            })}
            label="Описание"
            onBlur={(value) => {
              if (request?.Status?.code === 1) {
                updateRequest({
                  ...request,
                  RegActionId: request?.RegAction?.code,
                  RightTypeId: request?.RightType?.code,
                  ObjectTypeId: request?.Object?.Type?.code,
                  CadNumber: request?.Object?.CadNum,
                  IsPrivateFarming: request?.Object?.PrivateFarming,
                  IsFarming: request?.Object?.Farming,
                  Description: value,
                });
              }
            }}
          />
          <TextArea
            value={request?.Note}
            onChange={(value) => setRequest({
              ...request,
              Note: value
            })}
            label="Примечание для регистратора"
            onBlur={(value) => {
              if (request?.Status?.code === 1) {
                updateRequest({
                  ...request,
                  RegActionId: request?.RegAction?.code,
                  RightTypeId: request?.RightType?.code,
                  ObjectTypeId: request?.Object?.Type?.code,
                  CadNumber: request?.Object?.CadNum,
                  IsPrivateFarming: request?.Object?.PrivateFarming,
                  IsFarming: request?.Object?.Farming,
                  Note: value,
                });
              }
            }}
          />
          {/* <TextArea value={request?.Note} onChange={(value) => setRequest({ ...request, Note: value })} label="Комментарий" placeholder="Комментарий"></TextArea> */}
          {/* <Input placeholder="admin@signself.ru" label="Отправить результат на email" subLabel="Укажите электронную почту банка для отправки результата регистрации (для раскрытия аккредитива)"></Input> */}
        </CardBody>
      </CardContainer>
    }
    {!isNaN(id) &&
      <RequestFooter
        back={<Button text="Назад" href="/request/list"/>}
        next={
          <Button
            text={`${request?.Status?.code != 1 ? "Документы" : "Подписать документы"}`}
            onClick={() => {
              if (request?.Status?.code === 1 || request?.Status?.code === 2) {
                updateRequest({
                  ...request,
                  RegActionId: request?.RegAction?.code,
                  RightTypeId: request?.RightType?.code,
                  ObjectTypeId: request?.Object?.Type?.code,
                  CadNumber: request?.Object?.CadNum,
                  IsPrivateFarming: request?.Object?.PrivateFarming,
                  IsFarming: request?.Object?.Farming,
                }).then(x => navigate(`/request/signature/${ request?.Id }`))
              } else {
                navigate(`/request/signature/${ request?.Id }`)
              }
            }}
          />
        }
      />
    }
    <ObjectModal
      objectTypes={objectTypes}
      request={request}
      setRequest={(value) => setRequest(value)}
      isOpen={isBuildingsOpen}
      setOpen={(e) => setBuildingsOpen(e)}
    />
    <DocumentModal
      setRequest={setRequest}
      documentTypes={documentTypes}
      saveDocument={saveDocument}
      request={request}
      uploadedDocument={uploadedDocument}
      setUploadedDocument={(value) => setUploadedDocument(value)}
      isOpen={isDocumentModal}
      setOpen={(e) => setDocumentModal(e)}
      getRequest={getRequest}
    />
    {/* <Modal title="Тип регистрационного действия" isOpen={regTypeModal} setOpen={(e) => setRegTypeModal(e)} >
            <Dropdown isShown title="Регистрация прав, ограничений (обременений) прав">
                <ul>
                    <li style={{ cursor: "pointer" }} onClick={(e) => { selectRegType(e); setRegTypeModal(false) }}>Регистрация договора купли-продажи (ДКП)</li>
                    <li style={{ cursor: "pointer" }} onClick={(e) => { selectRegType(e); setRegTypeModal(false) }}>Регистрация нового право (на АПП, на застройщика)</li>
                </ul>
            </Dropdown>
            <Dropdown isShown title="Регистрация сделок">
                <ul>
                    <li style={{ cursor: "pointer" }} onClick={(e) => { selectRegType(e); setRegTypeModal(false) }}>Регистрация ДДУ</li>
                    <li style={{ cursor: "pointer" }} onClick={(e) => { selectRegType(e); setRegTypeModal(false) }}>Регистрация соглашения об уступке требований по ДДУ !</li>
                    <li style={{ cursor: "pointer" }} onClick={(e) => { selectRegType(e); setRegTypeModal(false) }}>Регистрация дополнительного соглашения к ДДУ !</li>
                    <li style={{ cursor: "pointer" }} onClick={(e) => { selectRegType(e); setRegTypeModal(false) }}>Регистрация соглашения о расторжении ДДУ !</li>
                </ul>
            </Dropdown>
        </Modal> */}
  </div >)
}