import React, { useEffect, useState } from "react";

import Modal from "../../Components/Modal/Modal";
import SmallCard from "../../Components/SmallCard/SmallCard";
import Select from "../../Components/Select/Select";
import Input from "../../Components/Input/Input";
import FileInput from "../../Components/FileInput/FileInput";
import { mimeTypes } from '../../constants/constants';

import styles from "./document.modal.styles.module.css"
import pdfIcon from "../../icons/PdfIcon.svg";
import check from "../../images/Check.svg"
import checkEmpty from "../../images/Check_Empty.svg"
import EmptyBlock from "../../Components/EmptyBlock/EmptyBlock";
import DocumentCard from "../../Components/DocumentCard/DocumentCard";
import UlSubjectCard from "../../Components/UlSubjectCard/UlSubjectCard";
import InputDocuments from "../../Components/Input/InputDocuments";
import useFileService from '../../Services/FileService';
import Badge from '../../Components/Badge/Badge';
import avatar from '../../images/Avatar_2.svg';
import { useUlService } from '../../Services/UlSubjectService';

export default function DocumentModal({ saveDocument, request,  uploadedDocument, isOpen, setOpen, setUploadedDocument, setRequest, getRequest }) {

  const [documentType, setDocumentType] = useState(null);
  const [participants, setParticipants] = useState([])
  const [series, setSeries] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [ulSubjects, setUlSubjects] = useState([]);
  const { getUlSubjects } = useUlService();

  const {downloadFile, downloadLocalFile, base64ToFile} = useFileService();

  const uploadDocument = () => {
    const participantIds = participants
      .filter(participant => participant.isSelected)
      .map(({ Id }) => Id);
      saveDocument(
        request.Id,
        uploadedDocument?.Type?.code,
        uploadedDocument?.File,
        participantIds,
        uploadedDocument?.Signature
      ).then(() => getRequest(request.Id)).then(() => setOpen(false))
      setRequest({
        ...request, Documents: [...request.Documents, {
          FileName: uploadedDocument.name,
          Type: uploadedDocument?.Type
        }]
      })
  }
  useEffect(() => {
    setParticipants(request?.Participants?.map((x) =>{return {...x ,isSelected: true} }))
  }, [isOpen]);

  useEffect(() => {
    getUlSubjects().then(result => setUlSubjects(result))
  }, [])

  const selectMember = (x) => {
    const newState = participants.map(obj => {
      if (obj.Id === x.Id) {
        return { ...obj, isSelected: !obj.isSelected };
      }
      return obj;
    });

    setParticipants(newState)
  }
  return (
    <Modal
      title="Добавление документа"
      onSave={uploadDocument}
      isOpen={isOpen}
      setOpen={setOpen}
    >
      <label>Документ</label>
      {(!uploadedDocument || !uploadedDocument?.FileName) &&
        <FileInput
          accept={[mimeTypes.Pdf, mimeTypes.Docx]}
          value={uploadedDocument?.File}
          onChange={(value) => setUploadedDocument({ ...uploadedDocument, File: value, FileName: value.name })}
        />
      }
      <React.Fragment>
        {uploadedDocument?.FileName != null &&
          <DocumentCard
            document={uploadedDocument}
            removeDocument={() => setUploadedDocument({ ...uploadedDocument, File: null, FileName: null })}
            onClick={() => {
              if (uploadedDocument?.FileUuid) {
                downloadFile(uploadedDocument?.FileUuid)
                  .then((base64) => base64ToFile(base64, uploadedDocument?.FileName))
                  .then((file) => downloadLocalFile(file, uploadedDocument?.FileName))
              }
            }}
          />
        }
        <label>Подпись</label>
        {!uploadedDocument?.Signature &&
          <FileInput
            accept={[mimeTypes.Sig]}
            value={uploadedDocument?.Signature}
            onChange={(value) => setUploadedDocument({ ...uploadedDocument, Signature: {...uploadedDocument?.Signature, File: value, Name: value?.name  }})}
          />
        }
        {uploadedDocument?.Signature != null &&
          <SmallCard
            title={uploadedDocument?.Signature?.Name}
            avatar={pdfIcon}
            onRemove={() => setUploadedDocument({ ...uploadedDocument, Signature: null})}
            onClick={() => {
              if (uploadedDocument?.Signature?.FileUuid) {
                downloadFile(uploadedDocument?.Signature?.FileUuid)
                  .then((base64) => base64ToFile(base64, uploadedDocument?.Signature?.Name))
                  .then((file) => downloadLocalFile(file, uploadedDocument?.Signature?.Name))
              }
            }}
          />
          // <DocumentCard document={sig} removeDocument={() => setSig(null)} ></DocumentCard>
        }
        <InputDocuments value={uploadedDocument?.Type} setValue={(value) => setUploadedDocument({ ...uploadedDocument, Type: value })}/>
        <Input
          value={uploadedDocument?.DocNumber}
          onChange={(value) => setUploadedDocument({ ...uploadedDocument, DocNumber: value })}
          placeholder=""
          label="Номер документа"
        />
        <Input
          value={series}
          onChange={(value) => setSeries(value)}
          placeholder=""
          label="Серия документа"
        />
        <Input
          value={issuedBy}
          onChange={(value) => setIssuedBy(value)}
          placeholder=""
          label="Кем выдан"
        />
        <Input
          label="Дата выдачи"
          value={uploadedDocument?.DocDate}
          mask={Date}
          placeholder='23.09.2023'
          onChange={(value) => setUploadedDocument({ ...uploadedDocument, DocDate: value })}
        />
        <div className={styles.documentMembers}>
          <span className={styles.documentMembersTitle}>
            Выберите заявителей (подписантов) для данного документа
          </span>
          {participants?.length === 0 &&
            <EmptyBlock title="Подписанты отсутсвуют"/>
          }
          {participants?.filter(x => x.UlAgent).map((x) =>
            <UlSubjectCard ulSubject={x} onSelect={() => selectMember(x)}/>
          )}
          {participants?.filter(x => x.FlSubject).map((x) =>
            <SmallCard
              key={x.Id}
              type={x.isSelected ? "primary" : "default"}
              onSelect={() => selectMember(x)}
              title={`${x.FlSubject.Lastname} ${x.FlSubject.Firstname} ${x.FlSubject.Middlename}`}
              subtitle={x.FlAgent ? `(Представитель: ${x.FlAgent.Lastname} ${x.FlAgent.Firstname} ${x.FlAgent.Middlename})` :""}
              avatar={x.isSelected ? check : checkEmpty}
            />
          )}
          {participants?.filter(x => x.UlAgentFlSubject).map((x) =>
            <SmallCard
              type={x.isSelected ? "primary" : "default"}
              key={x.Id}
              onSelect={() => selectMember(x)}
              title={`${x?.UlAgentFlSubject?.Lastname} ${x?.UlAgentFlSubject?.Firstname} ${x?.UlAgentFlSubject?.Middlename}`}
              subtitle={`Представитель: ${ulSubjects.find(subject => subject.Id === x?.UlAgentFlSubject?.UlSubjectId)?.Name}`}
              avatar={x.isSelected ? check : checkEmpty}
            />
          )}
        </div>
      </React.Fragment>
    </Modal>
  )
}