import { useContext } from 'react';
import { HttpContext } from '../../HttpContext';

export function useGrazhdotekaService() {
  const { httpClient } = useContext(HttpContext);

  const getAuthLink = async (customerId) => {
    return httpClient.get(`/Grazhdoteka/getAuthLink?customerId=${customerId}`).then(promise => promise.data)
  }

  return {
    getAuthLink
  }
}
