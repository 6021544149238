import { useContext, useEffect, useState } from 'react';
import { HttpContext } from '../../HttpContext';
import statuses from './signMeStatuses.json';

export function useSignmeService(id) {
  const { httpClient } = useContext(HttpContext);
  const [certificate, setCertificate] = useState({});
  const [status, setStatus] = useState(statuses[0]);

  useEffect(() => {
    if (!isNaN(id)) {
      getCertificateInfo().then(response => {
        setCertificate(response);
        statuses.filter((status) => status.code === response?.Status.code && setStatus(status))
      })
    }
  }, [id, status])

  const getCertificateInfo = async (flSubjectId) => {
    return httpClient.get(`/signme/flsubject/load/${flSubjectId ? flSubjectId : id}`).then(promise => promise.data)
  }

  const issueCertificate = async () => {
    return httpClient.post(`/signme/flsubject/issue/${id}`).then(promise => promise.data)
  }

  const uploadDocument = async (body) => {
    return httpClient.post(`/signme/flsubject/document/upload`, body).then(promise => promise.data)
  }

  const updateDocument = async (body) => {
    return httpClient.put(`/signme/flsubject/document/upload`, body).then(promise => promise.data)
  }

  const activateCertificate = async () => {
    return httpClient.post(`/signme/flsubject/activate/${id}`)
  }
  const signDocument = async (participantId, documentIds) => {
    const body = {
      "ParticipantId": participantId,
      "Documents": documentIds,
      "NoSendEmail": false,
      "ForceSms": false
    }
    return httpClient.post("/signme/signing-by/participant", body).then(promise => promise.data)
  }

  const signBuildingDocument = async (participantId, documentIds) => {
    const body = {
      "ParticipantId": participantId,
      "Documents": documentIds,
      "NoSendEmail": false,
      "ForceSms": false
    }
    return httpClient.post("/signme/building/storage-signing", body).then(promise => promise.data)
  }

  return { getCertificateInfo, issueCertificate, uploadDocument, updateDocument, activateCertificate, certificate, status, setCertificate, setStatus, signDocument, signBuildingDocument }
}
