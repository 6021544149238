import { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import Input from '../Input/Input';
import Button from '../Button/Button';
import Badge from '../Badge/Badge';

import styles from "./card.module.css";
import { Pencil } from '../Icon/Icon';

export default function CardHeader({ chevron, title, label, labelType = 'default', menu, button, onChangeTitle, onClick, reverseTitle }) {
  const [cardTitle, setCardTitle] = useState(title);
  const [cardTitleEdit, setCardTitleEdit] = useState(false);
  const inputRef = useRef(null);

  const handleSaveTitle = () => {
    setCardTitle(inputRef.current.value);
    setCardTitleEdit(false);
    onChangeTitle && onChangeTitle(inputRef.current.value)
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSaveTitle();
    }
  };

  const handleClickOutside = (e) => {
    if (inputRef.current && !inputRef.current.contains(e.target)) {
      handleSaveTitle();
    }
  };

  useEffect(() => {
    if (cardTitleEdit && inputRef.current) {
      inputRef.current.focus();
    }
  }, [cardTitleEdit])

  useEffect(() => {
    if (cardTitleEdit) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [cardTitleEdit]);

  return (
    <div className={styles.header} onClick={onClick && onClick}>
      <div className={styles.wrapper}>
        <h2 className={cn(styles.title, {[styles.reverse]: reverseTitle})} onClick={(e) => e.stopPropagation()}>
          {chevron && chevron}
          {onChangeTitle && cardTitleEdit ?
            <input
              ref={inputRef}
              className={styles.input}
              onKeyPress={handleKeyPress}
              value={cardTitle}
              onChange={(e) => {
                setCardTitle(e.target.value);
              }}
            />
            :
            <div className={styles.edit}>
              {cardTitle}
            </div>
          }
        </h2>
        {onChangeTitle && !cardTitleEdit &&
          <button className={styles.buttonIcon} onClick={(e) => {
            e.stopPropagation();
            setCardTitleEdit(!cardTitleEdit);
          }}>
            <Pencil className={styles.icon}/>
          </button>
        }
        {label && <Badge type={ labelType } text={label} /> }
      </div>

      <div className={styles.wrapper} onClick={(e) => e.stopPropagation()}>
        {button && button}
        {menu && menu}
      </div>
    </div>
  )
}
