import Badge from '../../../Components/Badge/Badge';

const signBadge = (signature) => {
  const signType = (type) => ({
    1: 'НУЦ',
    2: 'Токен',
    3: 'ID Point',
    4: 'Sign Me',
    5: 'Загруженный',
    6: 'Itcom',
    7: 'ИТ Мониторинг',
    8: 'Госключ',
  }[type] || null);

  const badgeProps = {
    0: { text: '• Ожидает подписания', type: 'error' },
    1: { text: '• Требуется подпись', type: 'error' },
    2: { text: `• Отправлено на подпись ${signType(signature?.Type) ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}` : ''}`, type: 'warning' },
    3: { text: `• Подписано ${signType(signature?.Type) ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}` : ''}`, type: 'success' },
    4: {
      text: `• Отказался от подписания ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'warning'
    },
    5: {
      text: `• Документ не найден ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'warning'
    },
    6: {
      text: `• Ошибка системы подписания ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'warning'
    },
    7: {
      text: `• Некорректный формат документов ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'warning'
    },
    8: {
      text: `• Учетная запись физического лица не найдена ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'warning'
    },
    9: {
      text: `• Подпись загружена ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'success'
    },
    10: {
      text: `• Обновление ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'warning'
    },
    11: {
      text: `• Истекло время подписания документов ${
        signType(signature?.Type)
          ? `(${signType(signature?.Type)}) ${signature?.DateUpdate}`
          : ''}`,
      type: 'warning'
    },
  };

  return signature?.Status
    ? <Badge margin {...badgeProps[signature.Status]} />
    : <Badge margin {...badgeProps[1]} />;
};


export default signBadge;
