import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import JSZip, { file } from "jszip";
import { FormControlLabel, Switch } from "@mui/material";

import CardHeader from "../../Components/Card/CardHeader";
import Input from "../../Components/Input/Input";
import SmallCard from "../../Components/SmallCard/SmallCard";
import Button from "../../Components/Button/Button";
import useInterval from "./useInterval";
import EmptyBlock from "../../Components/EmptyBlock/EmptyBlock";
import FileInput from "../../Components/FileInput/FileInput";
import CardContainer from "../../Components/Card/CardContainer";
import CardBody from "../../Components/Card/CardBody";
import Dropdown from "../../Components/Dropdown/Dropdown";
import Badge from '../../Components/Badge/Badge';
import { HttpContext } from "../../HttpContext";
import { mimeTypes } from '../../constants/constants';
import TableContainer from "../../Components/Table/TableContainer"
import TableCell from "../../Components/Table/TableCell"
import TableHeader from "../../Components/Table/TableHeader"

import uploadIcon from "../../icons/upload-icon.svg";
import pdfIcon from "../../icons/PdfIcon.svg";
import DownloadIcon from "../../icons/download.svg";
import styles from "./demo.page.module.css";

export default function DemoPage() {
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const { httpClient } = useContext(HttpContext);
  const [stats, setStats] = useState(null)
  const [isDisabled, setDisabled] = useState(false);
  const [isUkep, setUkep] = useState(true);
  const [myDocuments, setMyDocuments] = useState([]);
  const [certificates, setCertificates] = useState(null);
  const [inn, setInn] = useState("");
  const [inProgress, setInProgress] = useState([]);
  const [snils, setSnils] = useState("");
  const [company, setCompany] = useState("");
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  useEffect(() => {
    getStats()
    if (myDocuments.length === 0) {
      getMyDocuments()
    }
  }, [])

  const certificateStatus = [
    { id: 0, status: "Сертификат не найден" },
    { id: 1, status: "В процессе выпуска сертификата" },
    { id: 2, status: "Сертификат выпущен" },
    { id: 3, status: "Отказано в выпуске" },
    { id: 4, status: "Требуется перевыпуск" },
  ]

  const statuses = [
    {
      id: 17,
      status: "Ожидает подписания",
      statusClass: "error"
    },
    {
      id: 7,
      status: "Истекло время подписания",
      statusClass: "error"
    },
    {
      id: 3,
      status: "Подписано",
      statusClass: "success"
    },
    {
      id: 4,
      status: "Отказался от подписания",
      statusClass: "error"
    },
    {
      id: 10,
      status: "Ожидает подписания",
      statusClass: "error"
    },
    {
      id: 21,
      status: "Загрузка статуса",
      statusClass: "error"
    }
  ]


  useInterval(async () => {
    getMyDocuments()
  }, 25000)


  function addFiles(files) {
    const newArray = uploadedDocuments;
    Array.from(files).forEach(x => { x.name.replace(" ", "_"); newArray.push(x) })
    setUploadedDocuments(newArray)
  }

  async function getMyDocuments() {
    await httpClient.get(`/goskey/documents`)
      .then(response => setMyDocuments(response.data))
      .catch(ex => {
        // if (ex.response.status == 401) {
        //     window.location.href = "/login"
        // }
      })
  }
  async function getStats() {
    await httpClient.get(`/goskey/stats?from=${from}&to=${to}`)
      .then(response => setStats(response.data))
  }
  async function downloadZip(orderId) {
    await httpClient.get(`/goskey/signature?orderId=${orderId}&isFiles=true`, { responseType: 'blob' })
      .then(({ data: blob }) => {
        let zip = JSZip.loadAsync(blob)
          .then(res => {
            res.generateAsync({ type: "blob" })
              .then(f => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(f);
                a.download = `${orderId}.zip`;
                a.click()
              })
          }
          )
      })
      .catch(ex => alert("Попробуйте скачать ещё раз"))
  }
  async function downloadSignature(orderId, fileName) {
    console.log(fileName)
    await httpClient.get(`/goskey/signature?orderId=${orderId}&isFiles=true`, { responseType: 'blob' })
      .then(({ data: blob }) => {
        let zip = JSZip.loadAsync(blob)
          .then(res => {
            res.forEach(x => {
              fileName = fileName.split(".")[0].replace(/\s/g, '_');
              const sigName = x.split(".")[0].replace(/\s/g, '_');
              if (fileName != sigName) {
                res.remove(x)
              }
            })
            res.generateAsync({ type: "blob" })
              .then(f => {
                const a = document.createElement('a');
                a.href = URL.createObjectURL(f);
                a.download = `${fileName.split('.')[0]}.zip`;
                a.click()
              })
          }
          )
      })
      .catch(ex => alert("Попробуйте скачать ещё раз"));
  }

  function renderPackage(x) {
    const status = statuses.find(f => f.id === x.status);
    const title = `${x.snils} ${x.title} от ${x.created}, ${status.status} ${x.isUkep ? "УКЭП" : "УНЭП"} ${x.updated}. ${x.company}`

    return (
      <Dropdown title={title} >
        <div style={{ padding: "0 20px", gap: 10, display: "flex", flexDirection: "column" }}>
          {x.documents.length > 0 &&
            <span className={styles.download} onClick={() => downloadZip(x.orderId)}>Скачать всё</span>
          }
          {x.documents.map((filename) =>
            <SmallCard title={filename} avatar={pdfIcon} >
              {x.status === 3 &&
                <span
                  onClick={() => downloadSignature(x.orderId, filename)}
                  className={styles.download}
                >
                  Архив (файл + sig): скачать
                </span>
              }
            </SmallCard>
          )}
        </div>
      </Dropdown>
    )
  }

  function renderCard(order, fileName) {
    const status = statuses.find(st => st.id === order.status);
    if (order.status === 3) {
      return (
        <SmallCard
          title={fileName}
          status={<Badge type={status.statusClass} text={status.status} />}
          subtitle={`СНИЛС: ${order.snils}, от ${order.created} `}
          avatar={pdfIcon}
        >
          <span
            onClick={() => downloadSignature(order.orderId, fileName)}
            className={styles.download}
          >
            Архив (файл+sig): скачать
          </span>
        </SmallCard>
      )
    }
    else {
      return (
        <SmallCard
          title={fileName}
          status={status.status}
          statusClass={status.statusClass}
          subtitle={`СНИЛС: ${order.snils}`}
          avatar={pdfIcon}
        />
      )
    }
  }
  async function search() {
    setDisabled(true)
    await httpClient.get(`/admin/certificates?snils=${snils}&inn=${inn}`)
      .then(response => setCertificates(response.data))
    setDisabled(false)
  }

  async function send() {
    setDisabled(true)
    const headers = { 'Content-Type': 'multipart/form-data' }
    var formData = new FormData();

    uploadedDocuments.forEach(document => {
      formData.append("files", document);
    });

    formData.append("snils", snils)
    formData.append("company", company)
    formData.append("isUkep", isUkep)
    try {
      await httpClient.post("/goskey/documents", formData, { headers: headers })
        .then(
          res => {
            setMyDocuments([
              ...myDocuments,
              {
                orderId: res.data,
                snils: snils,
                status: 17,
                documents: uploadedDocuments.map(x => x.name)
              }
            ])
            setUploadedDocuments([])
          }
        )
    } catch (ex) {
      setDisabled(false)
    }
    setDisabled(false)
  }

  return (
    <div className={styles.container}>
      <h1 style={{ textAlign: "center", marginTop: 20 }}>
        Выпуск сертификата и подписание документов в Госключ
      </h1>
      <CardContainer>
        <CardHeader title="Отправка документов на подпись" />
        <CardBody>
          <span className={styles.subtitle}>
            Для выпуска сертификата загрузите документ который хотели бы подписать
          </span>
          <FileInput
            accept={[mimeTypes.Pdf, mimeTypes.Docx]}
            value={null}
            multiple
            onChange={(e) => addFiles(e)}
          />
          {uploadedDocuments.map((x) =>

            <SmallCard
              key={x.name}
              bgColor="#F9FAFB"
              onRemove={() => setUploadedDocuments(uploadedDocuments.filter(t => t.name !== x.name))}
              border="1px solid #D0D5DD"
              title={x.name}
              subtitle={`Размер: ${(x.size / 1024 / 1024).toFixed(1)} мб`}
              avatar={pdfIcon}
            />
          )
          }
          {uploadedDocuments.length > 0 &&
            <React.Fragment>
              <Input
                style={{ width: 350 }}
                value={snils}
                mask={"000-000-000 00"}
                onChange={(value) => setSnils(value)}
                label="Введите СНИЛС физического лица"
              />
              <Input
                style={{ width: 350 }}
                value={company}
                onChange={(value) => setCompany(value)}
                label="Введите название компании, от которой клиент увидит приглашение на подписание документов"
              />
              <FormControlLabel
                control={
                  <React.Fragment>
                    <span>УНЭП</span>
                    <Switch
                      color="secondary"
                      checked={isUkep}
                      onChange={() => setUkep(!isUkep)}
                    />
                  </React.Fragment>
                }
                label="УКЭП"
              />
              <Button
                isDisabled={isDisabled}
                onClick={() => send()}
                text="Отправить на подпись"
              />
            </React.Fragment>
          }
        </CardBody>
      </CardContainer>
      <CardContainer >
        <CardHeader title="Отправленные документы" />
        <CardBody>
          {myDocuments.filter(x => x.status === 17 || x.status === 21).length === 0 &&
            <span
              className={styles.download}
              onClick={() => downloadZip(inProgress[0].orderId)}
            >
              Скачать всё
            </span>
          }
          {myDocuments.filter(x => x.status === 17 || x.status === 21).map((order) =>
            order.documents.map((fileName) =>
              <React.Fragment key={fileName}>
                {renderCard(order, fileName)}
              </React.Fragment>
            )
          )}
          {myDocuments.filter(x => x.status === 17 || x.status === 21).length === 0 &&
            <EmptyBlock title="Отправленные документы" />
          }
        </CardBody>
      </CardContainer>
      <CardContainer>
        <CardHeader title="Подписанные пакеты" />
        <CardBody>
          {myDocuments.filter(x => x.status === 3).map((x) => (
            <React.Fragment key={x.orderId}>
              {renderPackage(x)}
            </React.Fragment>
          ))
          }
          {myDocuments.length === 0 &&
            <EmptyBlock title="Отправленные пакеты" />
          }
        </CardBody>
      </CardContainer>
      <CardContainer>
        <CardHeader title="Краткая сводка" />
        <CardBody>
          <div>
            <label>Дата</label>
            <div style={{ display: "flex", gap: 10, marginBottom: 10 }}>
              <Input label="От" placeholder="01.01.2024" value={from} style={{ maxWidth: 150 }} onChange={(value) => setFrom(value)} mask={"00.00.0000"}></Input>
              <Input label="До" placeholder="01.01.2025" value={to} style={{ maxWidth: 150 }} onChange={(value) => setTo(value)} mask={"00.00.0000"}></Input>
            </div>
            <Button onClick={() => getStats()} text="Обновить"></Button>

          </div>
          <TableContainer>
            <TableHeader>
              <TableCell title="Отправлено"></TableCell>
              <TableCell title="Ожидает подписания"></TableCell>
              <TableCell title="Подписано"></TableCell>
              <TableCell title="Отказ от подписания"></TableCell>
              <TableCell title="Просрочено"></TableCell>
              <TableCell title="УКЭП"></TableCell>
              <TableCell title="УНЭП"></TableCell>

            </TableHeader>
            <tbody>
              <td style={{ borderRight: "1px solid #9a9b9e40", padding: 12 }}>{stats?.sent}</td>
              <td style={{ borderRight: "1px solid #9a9b9e40", padding: 12 }}>{stats?.await}</td>
              <td style={{ borderRight: "1px solid #9a9b9e40", padding: 12 }}>{stats?.unep + stats?.ukep}</td>

              <td style={{ borderRight: "1px solid #9a9b9e40", padding: 12 }}>{stats?.reject}</td>
              <td style={{ borderRight: "1px solid #9a9b9e40", padding: 12 }}>{stats?.expired}</td>
              <td style={{ borderRight: "1px solid #9a9b9e40", padding: 12 }}>{stats?.ukep}</td>
              <td style={{ padding: 12 }}>{stats?.unep}</td>
            </tbody>
          </TableContainer>
        </CardBody>
      </CardContainer>
      <CardContainer>
        <CardHeader title="Поиск сертификатов физического лица" />
        <CardBody>
          <Input
            style={{ width: 350 }}
            value={snils}
            mask={"000-000-000 00"}
            onChange={(value) => setSnils(value)}
            label="Введите СНИЛС физического лица"
          />
          <Input
            style={{ width: 350 }}
            value={inn}
            mask={"0000 0000 0000"}
            onChange={(value) => setInn(value)}
            label="Введите ИНН физического лица (обязательно для поиска в ITMonitoring)"
          />
          <Button
            isDisabled={isDisabled}
            onClick={() => search()}
            text="Найти"
          />
        </CardBody>
      </CardContainer>
      {certificates != null &&
        <React.Fragment>
          <CardContainer>
            <CardHeader
              label={certificateStatus.find(x => x.id === certificates.signme.status).status}
              title="SignMe"
            />
            {certificates.signme.status === 2 &&
              <CardBody>
                <React.Fragment>
                  <span>
                    Дата окончания срока действия сертификата: {certificates.signme.expire}
                  </span>
                  <a
                    download="Заявление.pdf"
                    target="_blank"
                    href={`data:application/octet-stream;base64,${certificates.signme.request}`}
                    style={{ display: "flex", alignItems: "center", gap: 5, cursor: "pointer" }}
                  >
                    <span>Заявление </span>
                    <img src={DownloadIcon} />
                  </a>
                </React.Fragment>
              </CardBody>}
            {certificates.signme.status === 4 &&
              <CardBody>
                <React.Fragment>
                  <span style={{ color: "red" }}>
                    Требуется переустановка мобильного приложения Sign Me
                  </span>
                  <a
                    download="Заявление.pdf"
                    target="_blank"
                    href={`data:application/octet-stream;base64,${certificates.signme.request}`}
                    style={{ display: "flex", alignItems: "center", gap: 5, cursor: "pointer" }}
                  >
                    <span>Заявление </span>
                    <img src={DownloadIcon} />
                  </a>
                </React.Fragment>
              </CardBody>
            }
          </CardContainer>
          <CardContainer>
            <CardHeader
              label={certificateStatus.find(x => x.id === certificates.itmonitoring.status).status}
              title="ITMonitoring"
            />
            {certificates.itmonitoring.status === 2 &&
              <CardBody>
                {certificates.itmonitoring.status === 2 &&
                  <React.Fragment>
                    <span>
                      Дата окончания срока действия сертификата: {certificates.itmonitoring.expire}
                    </span>
                    <a
                      download="Заявление.pdf"
                      target="_blank"
                      href={`data:application/octet-stream;base64,${certificates.itmonitoring.request}`}
                      style={{ display: "flex", alignItems: "center", gap: 5, cursor: "pointer" }}
                    >
                      <span>Заявление </span>
                      <img src={DownloadIcon} />
                    </a>
                  </React.Fragment>
                }
              </CardBody>
            }
          </CardContainer>
          <CardContainer>
            <CardHeader
              label={certificateStatus.find(x => x.id === certificates.goskey.status).status}
              title="Госключ"
            />
            {certificates.goskey.status === 2 &&
              <CardBody>
                <span>
                  Дата окончания срока действия сертификата: {certificates.goskey.expire}
                </span>
              </CardBody>
            }
          </CardContainer>
        </React.Fragment>
      }
      <Button
        text="Выйти"
        onClick={() => localStorage.removeItem("token")}
        href="/login"
      />
    </div>
  )
}