import React, { useContext, useEffect, useState } from "react";

import Input from "../../../Components/Input/Input";
import Select from "../../../Components/Select/Select";
import Modal from "../../../Components/Modal/Modal";
import { HttpContext } from "../../../HttpContext";
import { useAddressService } from "../../../Services/AddressService";
import AddressInput from "../../../Components/Input/AddressInput";
import { useEstateObjectService } from '../../../Services/EstateObject/EstateObject';
import ObjectInput from '../../../Components/Input/ObjectInput';
import Check from '../../../Components/Check/Check';

export default function ObjectModal({ request, isOpen, setRequest, setOpen, objectTypes }) {
  const { httpClient } = useContext(HttpContext);
  const [complexList, setComplexList] = useState([]);
  const [object, setObject] = useState(null);

  const updateObject = async () => {
    const body = {
      Id: request?.Id,
      RegActionId: request?.RegAction?.code,
      ObjectTypeId: parseInt(request?.Object?.Type?.code),
      ObjectNote: request?.Note,
      AreaContract: request?.DduAreaContract,
      CadNumber: request?.Object?.CadNum,
      Address: request?.Object?.Address?.Note,
      Note: request?.Note,
    }
    if (request?.Object?.Type?.code === 2 || request?.Object?.Type?.code === 6 || request?.Object?.Type?.code === 9) {
      body.IsFarming = request?.Object?.Farming
      body.IsPrivateFarming = request?.Object?.PrivateFarming
    } else {
      body.IsFarming = null
      body.IsPrivateFarming = null
    }
    if (request.RegAction.code === 1) {
      body.ComplexId = parseInt(request.Object.Complex.code)
      body.HouseId = parseInt(request.Object.House.code)
    }
    if (request.RegAction.code === 5 || request.RegAction.code === 4) {
      body.Address = request.Object.Address
      body.RightTypeId = request.RightType.code
    }
    await httpClient.put(`/request`, body)
  }

  const getComplex = async () => {
    await httpClient.get("/complex").then(res => setComplexList(res.data.Data))
  }

  useEffect(() => {
    if (object) {
      setRequest({
        ...request,
        DduAreaContract: object?.Area,
        Object: {
          ...request.Object,
          CadNum: object?.CadNumber,
          Address: object?.Address,
          Type: objectTypes.find(x => x.code == object?.Type)
        }
      })
    }
  }, [object])

  useEffect(() => {
    getComplex()
  }, [])

  return (
    <Modal
      onSave={() => request?.Id ? updateObject() : ""}
      title="Добавление объекта недвижимости"
      isOpen={isOpen}
      setOpen={setOpen}
    >
      {request?.RegAction.code === 4 &&
        <React.Fragment>
          <ObjectInput value={object} setValue={setObject}/>
        </React.Fragment>
      }
      <Select
        value={request?.Object?.Type?.code}
        placeholder="Выберите вид объекта"
        onChange={(value) => setRequest({
          ...request,
          Object: {
            ...request?.Object,
            Type: objectTypes.find(x => x.code == value)
          }
        })}
        label="Вид объекта"
      >
        {objectTypes.map((x) =>
          <option value={x.code}>{x.name}</option>
        )}
      </Select>
      {!(request?.RegAction.code === 5 || request?.RegAction.code === 4) &&
        <React.Fragment>
          <Select
            label="Комплекс"
            placeholder="Выберите комплекс"
            value={request?.Object?.Complex?.code}
            onChange={(value) => setRequest({
              ...request,
              Object: {
                ...request.Object,
                Complex: {
                  ...request.Object.Complex,
                  code: value
                }
              }
            })}
          >
            {complexList.map((x) =>
              <option value={x.Id}>{x.Name}</option>
            )}
          </Select>
          <Select
            value={request?.Object?.House?.code}
            onChange={(value) => setRequest({
              ...request,
              House: {
                ...request.Object.House,
                code: value
              }
            })}
            label="Дом"
            placeholder="Выберите дом в комплексе"
          >
            {complexList.filter(x => x.Id == request?.Object?.Complex?.code)
              .flatMap(x => x.House)
              .map((x) =>
                <option value={x.Id}>{x.Number}</option>
              )}
          </Select>
        </React.Fragment>
      }


      {(request?.RegAction.code === 1 || request?.RegAction.code === 4) &&
        <React.Fragment>
          <Input
            value={request?.DduAreaContract?.toString()}
            onChange={(value) => setRequest({
              ...request,
              DduAreaContract: value
            })}
            label="Площадь объекта по договору, м2"
          />
        </React.Fragment>
      }

      {(request?.RegAction.code === 5 || request?.RegAction.code === 4) &&
        <React.Fragment>
          <Input
            value={request?.Object?.CadNum}
            onChange={(value) => setRequest({
              ...request,
              Object: {
                ...request.Object,
                CadNum: value
              }
            })}
            label="Кадастровый номер"
            mask="00:00:000000:000000000"
            placeholder="__:__:______:_"
          />
          <AddressInput value={request?.Object?.Address}setValue={(value)=>setRequest({
                ...request,
                Object: {
                  ...request?.Object,
                  Address: value
                }
              })}></AddressInput>
        </React.Fragment>
      }
      {request?.Object?.Type?.code === 9 &&
        <React.Fragment>
          <Check
            label="Объект относится к землям сельскохозяйственного назначения"
            selected={request.Object.Farming}
            onClick={() =>
              setRequest({
                ...request,
                Object: { ...request.Object, Farming: !request.Object.Farming },
              })
            }
          />

          <Check
            label="Объект является земельным участком, предназначенным для ведения личного подсобного, дачного хозяйства, огородничества, садоводства, индивидуального гаражного или индивидуального жилищного строительства или расположен на таком участке"
            selected={request.Object.PrivateFarming}
            onClick={() =>
              setRequest({
                ...request,
                Object: { ...request.Object, PrivateFarming: !request.Object.PrivateFarming },
              })
            }
          />
        </React.Fragment>
      }
      <Input
        value={request?.Note}
        onChange={(value) => setRequest({
          ...request,
          Note: value
        })}
        label="Описание объекта"
      />
    </Modal >
  )
}