import React, { useEffect, useState } from 'react';

import CardContainer from '../../Components/Card/CardContainer';
import CardHeader from '../../Components/Card/CardHeader';
import CardBody from '../../Components/Card/CardBody';
import Button from '../../Components/Button/Button';
import TableHeader from '../../Components/Table/TableHeader';
import TableCell from '../../Components/Table/TableCell';
import TableFooter from '../../Components/Table/TableFooter';
import TableContainer from '../../Components/Table/TableContainer';
import { Pagination, Snackbar } from '@mui/material';
import ModalCenter from '../../Components/ModalCenter/ModalCenter';
import { useCustomerService } from '../../Services/Cusomer/Customer';
import Select from '../../Components/Select/Select';
import Input from '../../Components/Input/Input';
import EmptyBlock from '../../Components/EmptyBlock/EmptyBlock';
import TooltipSmallText from '../../Components/Tooltip/TooltipSmallText';
import Badge from '../../Components/Badge/Badge';
import TooltipText from '../../Components/Tooltip/TooltipText';

import styles from './autopay.module.css';
import useFileService from '../../Services/FileService';

const Autopay = () => {
  const [allBalance, setAllBalance] = useState();
  const [sharedBalance, setSharedBalance] = useState(0);
  const [transactions, setTransactions] = useState();
  const [balanceHistory, setBalanceHistory] = useState();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [customers, setCustomers] = useState();
  const [value, setValue] = useState(null);
  const [period, setPeriod] = useState(0);
  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const isSuperadmin = localStorage.getItem("isSuperadmin") === "true";
  const customerId = localStorage.getItem("customerId");

  const {downloadLocalFile} = useFileService()
  const {getCustomerList, getSharedPayBalance, getPayBalanceList, addPayBalance, getPayOperations, getBalanceHistory, getBalanceStatistic} = useCustomerService();

  const handleChange = (e, number) => {
    setPage(number);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const getBalance = (sum) => {
    if (sum === 0) return sum;

    const totalBalance = allBalance?.reduce((accumulator, currentItem) => accumulator + currentItem.Value, 0) || 0;
    return sum - (totalBalance / 100);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const getStatus = (status) => {
    switch (status) {
      case "created":
        return { text: "Платеж создан", badge: "info" };
      case "auth":
        return { text: "Оплачено", badge: "success" };
      case "cancel":
        return { text: "Платеж отменён", badge: "warning" };
      case "refunded":
        return { text: "Платеж возвращен", badge: "error" };
      default:
        return { text: "Платеж создан", badge: "info" };
    }
  };

  const getFile = () => {
    getBalanceStatistic(period).then(file => {
      downloadLocalFile(file, 'autopay-stat.xlsx')
    }).catch(() => {
      setOpen(true);
      setSnackbarMessage('За выбранный период ничего не найдено')
    })
  }

  useEffect(() => {
    getPayBalanceList().then((x) => {
      setAllBalance(x);
    })
    getBalanceHistory(isSuperadmin ? -1 : Number(customerId)).then((x) => setBalanceHistory(x)).catch(() => null)
    getCustomerList().then((customers) => {
      setCustomers(customers.Value);
    })
    if (isSuperadmin) {
      getSharedPayBalance().then((x) => setSharedBalance(x))
    }
  }, [])

  useEffect(() => {
    getPayOperations().then((x) => {
      setTransactions(x);
      setTotal(x?.TotalPages);
    }).catch(() => null)
  }, [page])

  return (
    <div className={styles.container}>
      <h1>Все операции</h1>
      {isSuperadmin &&
        <div>
          {allBalance?.length > 0 &&
            <CardContainer>
              <CardHeader title='Баланс автооплаты' button={<Button onClick={() => setIsOpenModal(!isOpenModal)} text='Пополнить'/>}/>
              <CardBody>
                <div className={styles.wrapper}>
                  <div className={styles.card}>
                    <span className={styles.title}>Администрирование системы</span>
                    <span>{ `Общий баланс: ${sharedBalance / 100} рублей` }</span>
                    <span>{ `На распределении: ${getBalance(sharedBalance / 100)} рублей` }</span>
                  </div>
                  {allBalance?.map((item, index) =>
                    <div key={index} className={styles.card}>
                      <span className={styles.title}>{item.CustomerName}</span>
                      <span>{ `${item.Value / 100} рублей` }</span>
                    </div>
                  )}
                </div>
              </CardBody>
            </CardContainer>
          }
        </div>
      }
      <CardContainer>
        <CardHeader/>
        <CardBody>
          <div className={styles.excel}>
            <div className={styles.select}>
              <Select placeholder='Период отчета' value={period} onChange={(value) => setPeriod(value)}>
                <option value={0}>
                  За все время
                </option>
                <option value={1}>
                  За прошлый месяц
                </option>
              </Select>
            </div>
            <Button
              onClick={getFile}
              text='Скачать отчёт'
            />
          </div>
        </CardBody>
      </CardContainer>
      <div>
        {transactions?.length > 0 ?
          <TableContainer>
            <TableHeader>
              <TableCell th="Контрагент"/>
              <TableCell th="Номер заявки"/>
              <TableCell th="Адрес объекта"/>
              <TableCell th="УИН"/>
              <TableCell th="Сумма"/>
              <TableCell th="Плательщик"/>
              <TableCell th="Дата и время оплаты"/>
              <TableCell th="Статус"/>
            </TableHeader>
            <tbody>
            {transactions?.map((transaction) =>
              <tr key={transaction?.Id}>
                <TableCell th={transaction?.CustomerName}/>
                <TableCell th={transaction?.RequestNumber}/>
                <TableCell th={<TooltipText small text={transaction?.Address} maxLength='20'/>}/>
                <TableCell th={transaction?.Uin}/>
                <TableCell th={`${transaction?.TotalSum / 100} р`}/>
                <TableCell th={<TooltipSmallText text={transaction?.PayerName} maxLength='20'/>}/>
                <TableCell th={formatDate(transaction?.StatusDate)}/>
                <TableCell th={<Badge type={getStatus(transaction?.StatusName).badge} text={getStatus(transaction?.StatusName).text} />}/>
              </tr>
            )}
            </tbody>
          </TableContainer>
          :
          <EmptyBlock title='Нет данных по оплате'/>
        }
        {/*{transactions?.length > 0 &&*/}
        {/*  <TableFooter>*/}
        {/*    <Pagination*/}
        {/*      count={total}*/}
        {/*      page={page}*/}
        {/*      onChange={handleChange}*/}
        {/*      variant="outlined"*/}
        {/*      color="secondary"*/}
        {/*    />*/}
        {/*  </TableFooter>*/}
        {/*}*/}
      </div>
      {balanceHistory?.length > 0 &&
        <div className={styles.history}>
          <h2>История пополнений</h2>
          <TableContainer>
            <TableHeader>
              <TableCell th="Контрагент"/>
              <TableCell th="Сумма пополнения"/>
              <TableCell th="Дата пополнения"/>
            </TableHeader>
            <tbody>
            {balanceHistory?.map((balance) =>
              <tr key={balance?.Id}>
                <TableCell th={customers?.find((item) => item.code === balance?.CustomerId)?.name}/>
                <TableCell th={`${balance?.Value / 100} р`}/>
                <TableCell th={formatDate(balance?.Date)}/>
              </tr>
            )}
            </tbody>
          </TableContainer>
        </div>
      }
      <ModalCenter fullHeight isOpen={isOpenModal} setIsOpen={setIsOpenModal} closeButton>
        <h2>Пополнение баланса</h2>
        <div className={styles.balance}>
          {customers?.length > 0 &&
            <Select
              value={selectedCustomer}
              label="Контрагент"
              placeholder="Выберите контрагента"
              onChange={(value) => setSelectedCustomer(value)}
            >
              {customers?.map((item, index) => (
                <option key={index} value={item.code}>
                  {item.name}
                </option>
              ))}
            </Select>
          }
          <Input mask={Number} value={value} onChange={(value) => setValue(value)} label="Сумма, р"/>
        </div>
        <div className={styles.buttons}>
          <Button
            isDisabled={!selectedCustomer || !value}
            text='Пополнить'
            onClick={() => {
              addPayBalance(selectedCustomer, value * 100).then(() => {
                setSnackbarMessage(`Баланс пополнен на ${value} рублей`)
                setOpen(true);
                setIsOpenModal(false);
                getPayBalanceList().then((x) => setAllBalance(x))
                getBalanceHistory(isSuperadmin ? -1 : Number(customerId)).then((x) => setBalanceHistory(x)).catch(() => null)
                getPayOperations().then((x) => {
                  setTransactions(x);
                  setTotal(x?.TotalPages);
                })
              }).catch(() => {
                setSnackbarMessage('Произошла ошибка')
                setOpen(true);
                setIsOpenModal(false);
              })
            }}
          />
        </div>
      </ModalCenter>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
        autoHideDuration={5000}
        message={snackbarMessage}
      />
    </div>
  );
};

export default Autopay;
