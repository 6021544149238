import React, { useEffect, useState } from 'react';
import FileInput from "../../../Components/FileInput/FileInput";
import Modal from "../../../Components/Modal/Modal";
import Select from "../../../Components/Select/Select";
import SmallCard from "../../../Components/SmallCard/SmallCard";
import avatar from "../../../images/Avatar_2.svg";
import Input from "../../../Components/Input/Input";
import { mimeTypes } from "../../../constants/constants";
import pdfIcon from "../../../icons/PdfIcon.svg";
import useRequestContext from '../useRequestContext';
import styles from '../UlSubject/add.ul.module.css';

export default function AddFlSubjectModal({ flSubject, isOpen, setOpen, flSubjects, typeId }) {
  const { FlSubject, FlAgent, Document } = flSubject || {};
  const [state, setState] = useState({
    subject: FlSubject || flSubject || null,
    flAgent: FlAgent?.Id || null,
    documentDate: Document?.Date || null,
    document: Document?.FileName || null,
    sig: Document?.SigFileName || null,
    documentTypeCode: Document?.Type?.code || null,
    FractionNumerator: null,
    FractionDenominator: null
  });

  const { request, documentTypes, getRequest, addFlSubject, addFlSubjectWithAgent, updateFlSubject, updateFlSubjectWithAgent } = useRequestContext();

  const onSaveFl = () => {
    if (!state.flAgent) {
      addFlSubject(state.subject.Id, request.Id, typeId, state?.FractionNumerator, state?.FractionDenominator).then(() => getRequest(request.Id));
    } else {
      addFlSubjectWithAgent(state.subject.Id, request.Id, state.documentTypeCode, state.documentDate, state.document, state.sig, state.flAgent, typeId).then(() =>
        getRequest(request.Id)
      );
    }
  };

  useEffect(() => {
    if (flSubject) {
      setState({
        subject: FlSubject || flSubject,
        flAgent: FlAgent?.Id || null,
        documentDate: Document?.Date || null,
        document: Document?.FileName || null,
        sig: Document?.SigFileName || null,
        documentTypeCode: Document?.Type?.code || 0,
        FractionNumerator: flSubject?.Numerator,
        FractionDenominator: flSubject?.Denominator
      });
    }
  }, [flSubject]);

  const handleInputChange = (field, value) => {
    setState((prevState) => ({ ...prevState, [field]: value }));
  };

  return (
    <Modal onSave={onSaveFl} title="Добавление участника (физического лица)" isOpen={isOpen} setOpen={setOpen}>
      <label>Участник</label>
      <SmallCard
        title={`${state.subject?.Lastname} ${state.subject?.Firstname} ${state.subject?.Middlename}`}
        subtitle={`СНИЛС: ${state.subject?.Snils}`}
        avatar={avatar}
      />
      <Select value={state.flAgent} onChange={(id) => handleInputChange('flAgent', id)} placeholder='Выберите представителя' label="Представитель">
        {flSubjects?.map((x) => (
          <option key={x.Id} value={x.Id}>
            {`${x.Lastname} ${x.Firstname}, СНИЛС: ${x.Snils}`}
          </option>
        ))}
      </Select>
      {(request?.RightType?.code === '001002000000' && typeId !== 2)
        ?
        <div>
          <label>Доля</label>
          <div className={styles.wrapper}>
            <Input
              value={state?.FractionNumerator ?? ''}
              onChange={(value) => {
                const numericValue = value.replace(/[^0-9]/g, '');
                setState({...state, FractionNumerator: numericValue ? Number(numericValue) : null});
              }}
            />
            <span>/</span>
            <Input
              value={state?.FractionDenominator ?? ''}
              onChange={(value) => {
                const numericValue = value.replace(/[^0-9]/g, '');
                setState({...state, FractionDenominator: numericValue ? Number(numericValue) : null});
              }}
            />
          </div>
        </div>
        :
        null
      }
      {state.flAgent && (
        <>
          <label>Документ, подтверждающий полномочия</label>
          {state.document ?
            <SmallCard
              onRemove={() => handleInputChange('document', null)}
              avatar={pdfIcon}
              type="default"
              title={state.document.name ? state.document.name : state.document}
            />
            :
            <FileInput accept={[mimeTypes.Pdf, mimeTypes.Docx]} onChange={(value) => handleInputChange('document', value)} />
          }
          <label>Электронная подпись</label>
          {state.sig ?
            <SmallCard
              onRemove={() => handleInputChange('sig', null)}
              avatar={pdfIcon}
              type="default"
              title={state.sig.name ? state.sig.name : state.sig}
            />
            :
            <FileInput accept={[mimeTypes.Sig]} onChange={(value) => handleInputChange('sig', value)} />
          }
          <Select value={state.documentTypeCode} onChange={(value) => handleInputChange('documentTypeCode', value)} label="Тип документа">
            {documentTypes.map((x) => (
              <option key={x.code} value={x.code}>
                {x.name}
              </option>
            ))}
          </Select>
          <Input mask={Date} value={state.documentDate} onChange={(value) => handleInputChange('documentDate', value)} label="Дата выдачи" />
        </>
      )}
    </Modal>
  );
}
