import React, { useContext, useEffect, useState } from "react";
import { Pagination, Tooltip } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";

import TableContainer from "../../../Components/Table/TableContainer";
import TableHeader from "../../../Components/Table/TableHeader";
import TableCell from "../../../Components/Table/TableCell";
import TableFooter from "../../../Components/Table/TableFooter";
import InnerCell from "../../../Components/Table/InnerCell";
import Button from "../../../Components/Button/Button";
import TableSkeleton from '../../../Components/Table/TableSkeleton';
import { useFlService } from '../../../Services/FlSubjectService';
import { useUlService } from '../../../Services/UlSubjectService';
import Badge from '../../../Components/Badge/Badge';
import Input from '../../../Components/Input/Input';
import EmptyBlock from '../../../Components/EmptyBlock/EmptyBlock';

import plusIcon from "../../../icons/XCircle.svg";
import styles from "./customers.table.module.css";
import gosuslugi from '../../../icons/gosuslugi.svg'


export default function CustomersTable() {
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(10);
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [type, setType] = useState("fl");
  const [searchValue, setSearchValue] = useState('');
  const [openTooltip, setOpenTooltip] = useState(false);

  const { getFlSubjectsPage, searchFlSubjects } = useFlService();
  const { getUlSubjectsPage } = useUlService();

  const handleChange = (e, number) => {
    setPage(number);
  };

  async function getCustomers() {
    if (searchValue?.length > 1) {
      searchFlSubjects(searchValue, page).then(x=> {
        if (x?.Data) {
          setCustomers(x.Data);
          setTotal(x.Total);
        } else {
          setCustomers(null);
          setTotal(null);
        }
      })
    } else {
      if (searchValue?.length === 0) {
        setPage(page ? page : 1)
      }
      const fetchCustomersPage = type === "fl" ? getFlSubjectsPage : getUlSubjectsPage;
      const response = await fetchCustomersPage(page, pageCount);

      setCustomers(response.Data);
      setTotal(response.Total);
    }
  }

  useEffect(() => {
    getCustomers()
  }, [page, type, searchValue])

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", flexDirection: "row", gap: 12 }}>
        <Button
          type='light'
          isDisabled={type === "fl"}
          onClick={() => {
            setType("fl")
            setCustomers([])
          }}
          text="Физ Лица"
        />
        <Button
          type='light'
          isDisabled={type === "ul"}
          onClick={() => { setType("ul"); setCustomers([]) }} text="Юр Лица"/>
      </div>
      {type === 'fl' &&
        <Input value={searchValue} onChange={(value) => setSearchValue(value)} placeholder='Поиск по Физ лицам'/>
      }
      <TableContainer>
        <TableHeader>
          {type === "fl" &&
            <React.Fragment>
              <TableCell th="Клиент/Тип"/>
              <TableCell th="Контакты"/>
              <TableCell th="СНИЛС"/>
              <TableCell th="Дата создания"/>
              <TableCell th="ЭП"/>
              <TableCell th="Госуслуги"/>
            </React.Fragment>
          }
          {type === "ul" &&
            <React.Fragment>
              <TableCell th="Клиент/Тип"/>
              <TableCell th="Контакты"/>
              <TableCell th="ИНН"/>
              <TableCell th="КПП"/>
              <TableCell th="ОГРН"/>
              <TableCell th="Дата создания"/>
            </React.Fragment>
          }
        </TableHeader>
        <tbody>
        {customers?.length === 0 && searchValue?.length === 0 &&
          <TableSkeleton col={pageCount} row={type === "fl" ? 5 : 6} />
        }
        {type === "fl" && customers?.map((x) =>
          <tr key={x.Id} onClick={() => navigate(`/customer/edit/${x.Id}/fl`)}>
            <TableCell
              title={`${x.Lastname} ${x.Firstname} ${x.Middlename}`}
              subtitle='Физ.лицо'
            />
            <TableCell title={x.Phone} subtitle={x.Email} />
            <TableCell title={x.Snils}/>
            <TableCell title={x.DateCreate} />
            <TableCell status={<Badge type="success" text={x.certificate}/>}/>
            <TableCell
              badge={x?.IsAgree
                ?
                <Tooltip
                  arrow
                  title={`Дата обновления: ${x.DateUpdate}`}
                  onMouseEnter={() => setOpenTooltip(true)}
                  onMouseLeave={() => setOpenTooltip(false)}
                  open={openTooltip}
                >
                  <img style={{width: '30px', height: '30px'}} src={gosuslugi} />
                </Tooltip>
                :
                ''
              }
            />
          </tr>
        )}
        {type === "ul" && customers?.map((x) =>
          <tr key={x.Id} onClick={() => navigate(`/customer/edit/${x.Id}/ul`)}>
            <TableCell title={x.Name} subtitle="Юр.лицо"/>
            <TableCell title={x.Phone} subtitle={x.Email} />
            <TableCell title={x.Inn}/>
            <TableCell title={x.Kpp}/>
            <TableCell title={x.Ogrn}/>
            <TableCell title={x.DateCreate} />
          </tr>
        )}
        </tbody>
      </TableContainer>
      {customers?.length === 0 && searchValue?.length !== 0 &&
        <EmptyBlock title='Нет результатов поиска'/>
      }
      <TableFooter>
        <Pagination
          count={Math.ceil(total / pageCount)}
          page={page}
          onChange={handleChange}
          variant="outlined"
          color="secondary"
        />
      </TableFooter>
    </div>
  )
}